import { useBaseData, BaseDataProvider } from '../../../common/base_data';
import { daysBetween } from '../../../common/util';
import {
  ClientDrillDown,
  format,
  PageDataGrid,
  QuickFilterSpec,
} from '../../../common/components';
import * as _ from 'ramda';
import { GridColDef } from '@mui/x-data-grid-pro';
import h from '../../../lib/react-hyperscript';

import DataGridEntryLink from '../../../common/components/DataGridEntryLink';
import { LeagueId } from '../../../common/types';
import { PackageDrillDown } from '../../../common/components/PackageDrillDown';
import { SellthruWidget } from '../../../common/components/SellthruWidget';

const ldws = (row: {
  daily_listed_counts: number[];
  daily_sold_counts: number[];
}) => {
  if (!row.daily_listed_counts || !row.daily_sold_counts) return null;
  // find the index of the first non zero value in the sold counts
  const first_sold = row.daily_sold_counts.findIndex((x) => x > 0);
  // sum all the listed counts before the first sold
  const ldws = row.daily_listed_counts
    .slice(0, first_sold)
    .reduce((acc, x) => acc + x, 0);

  return Math.round(ldws);
};

const ldpsSparklineData =
  (opts: { days: number; avg_window: number }) => (row: any) => {
    if (!row.daily_listed_counts || !row.daily_sold_counts) {
      return {
        max: 100,
        min: 0,
        points: [],
      };
    }

    const dlc = row.daily_listed_counts as number[];
    const dsc = row.daily_sold_counts as number[];

    const dlc_smooth = dlc.map((_x, i) => {
      const window = Math.min(opts.avg_window, dlc.length - i);
      const sum = dlc
        .slice(i, i + window)
        .reduce((acc: number, x: number | null) => acc + (x ?? 0), 0);
      return sum / window;
    });

    const dsc_smooth = dsc.map((_x, i) => {
      const window = Math.min(opts.avg_window, dsc.length - i);
      const sum = dsc
        .slice(i, i + window)
        .reduce((acc: number, x: number | null) => acc + (x ?? 0), 0);
      return sum / window;
    });

    const ldps: number[] = [];
    for (let i = 0; i < dlc_smooth.length; i += 1) {
      const listed_days = dlc_smooth
        .slice(i, i + opts.days)
        .reduce((acc, x) => acc + x, 0);
      const sold_days = dsc_smooth
        .slice(i, i + opts.days)
        .reduce((acc, x) => acc + x, 0);
      ldps.push(
        Math.round(
          sold_days ? listed_days / sold_days : listed_days ? Infinity : 0,
        ),
      );
    }

    // if (row.id === 16283) {
    //   console.log(
    //     'Package',
    //     row.id,
    //     opts,
    //     row.daily_listed_counts,
    //     row.daily_sold_counts,
    //     ldps,
    //   );
    // }

    return {
      max: 100,
      min: 0,
      points: ldps
        .map((x) => ({
          value: x,
          opacity: 1,
        }))
        .reverse(),
    };
  };

const column_specs: GridColDef[] = [
  {
    field: 'client_id',
    align: 'center',
    width: 80,
  },
  {
    field: 'client_name',
    headerName: 'name',
    flex: 1,
    minWidth: 150,
    maxWidth: 300,
    valueGetter: ({ row }) => row.client_name,
    renderCell: (item) => {
      return h(ClientDrillDown, {
        label: item.value,
        client_id: item.row.client_id,
      });
    },
  },
  {
    field: 'package_id',
    headerName: 'pkg_id',
    width: 80,
    renderCell: (params) =>
      DataGridEntryLink(
        { id: params.row.id },
        `/pricing/inventory?filter.01.package_id=equals%2C${params.row.id}&v=2`,
      ),
  },
  {
    field: 'package_short',
    headerName: 'package',
    minWidth: 270,
    valueGetter: ({ row }) =>
      `'${row.season - 2000} ${row.league} ${row.team_abbrev} • ${
        row.section
      } • ${row.row} • ${row.first_seat + '-' + row.last_seat}`,
    renderCell: (params) =>
      PackageDrillDown({
        label: params.value,
        package_id: params.row.id,
      }),
  },

  {
    field: 'dps_10',
    flex: 1,
    minWidth: 120,
    valueGetter: ({ row }) =>
      row.listed + row.sold === 0
        ? null
        : ldpsSparklineData({ days: 1, avg_window: 10 })(row),
    sortComparator: (a, b) => {
      if (a === null && b === null) return 0;
      if (a === null) return -1;
      if (b === null) return 1;

      if (a.points.length === 0 && b.points.length === 0) return 0;
      if (a.points.length === 0) return -1;
      if (b.points.length === 0) return 1;
      return (
        a.points[a.points.length - 1].value -
        b.points[b.points.length - 1].value
      );
    },

    renderCell: ({ value, row }) => {
      if (!value) return null;
      return row.daily_listed_counts
        ? h(SellthruWidget, {
            data: value,
            points_shown: 30,
          })
        : null;
    },
  },
  {
    field: 'dsc',
    headerName: 'dsc',
    description: 'Days Since Calibration',
    width: 50,
    valueGetter: ({ row }) =>
      row.last_adjustment ? daysBetween(row.last_adjustment, new Date()) : null,
    valueFormatter: ({ value }) => (_.isNil(value) ? null : value.toFixed(0)),
    type: 'number',
  },
  {
    field: 'dws',
    align: 'right',
    description: 'Days Without Sale',
    width: 50,
    valueGetter: ({ row }) =>
      row.last_sold ?? row.first_listed
        ? daysBetween(row.last_sold ?? row.first_listed, new Date())
        : null,
    valueFormatter: ({ value }) => (_.isNil(value) ? null : value.toFixed(0)),
    type: 'number',
  },
  {
    field: 'ldws',
    headerName: 'ldws',
    description:
      'Listed Days Without Sale (NB: weighted and only counting last 30 days)',
    width: 50,
    valueGetter: ({ row }) => ldws(row),
    align: 'right',
    type: 'number',
  },
  {
    field: 'last_sold',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },

  {
    field: 'tickets',
    align: 'right',
    type: 'number',
    width: 60,
  },
  {
    field: 'pct_listed',
    headerName: 'listed%',
    align: 'right',
    type: 'number',
    valueFormatter: format.percent,
    width: 60,
  },
  {
    field: 'pct_sold',
    headerName: 'sold%',
    align: 'right',
    type: 'number',
    valueFormatter: format.percent,
    width: 60,
  },
  {
    field: 'pct_delisted',
    headerName: 'delisted%',
    align: 'right',
    type: 'number',
    valueFormatter: format.percent,
    width: 60,
  },

  {
    field: 'unsold_model_diff_avg',
    headerName: 'unsolds_mΔ',
    description: 'Unsold Model Comp',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
  },
  {
    field: 'sold_model_diff_avg',
    headerName: 'sold_mΔ',
    description: 'Sold Model Comp',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
  },
  {
    field: 'list_change_avg',
    headerName: 'list±',
    description: 'List Price Change',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
  },
  {
    field: 'listed_value',
    headerName: '$listed',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'sold_value',
    headerName: '$sold',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'unlisted_value',
    headerName: '$unlisted',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'delisted_value',
    headerName: '$delisted',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },

  {
    field: 'is_playoff',
    width: 75,
    type: 'boolean',
  },
  { field: 'league', width: 75 },
  { field: 'season', width: 75 },
  {
    field: 'team',
    width: 80,
    renderCell: (params) =>
      DataGridEntryLink(
        { id: params.row.team },
        `/pricing/packages?filter.01.team=equals%2C${params.row.team}&filter.02.season=equals%2C${params.row.season}&filterOp=and&v=2`,
      ),
  },
  { field: 'section', width: 75 },
  { field: 'row', width: 50 },
  {
    field: 'seats',
    valueGetter: ({ row }) => row.first_seat + '-' + row.last_seat,
    minWidth: 50,
    maxWidth: 75,
  },
  {
    field: 'amp',
    width: 75,
    type: 'boolean',
  },
  {
    field: 'is_shown',
    width: 75,
    type: 'boolean',
  },
];

const column_groups = [
  {
    groupId: 'Entry',
    children: [{ field: 'id' }],
  },
  {
    groupId: 'Package',
    children: [
      { field: 'client_id' },
      { field: 'client_name' },
      { field: 'package_id' },
      { field: 'package_short' },
    ],
  },

  {
    groupId: 'Velocity',
    children: [
      { field: 'dps_10' },
      { field: 'dsc' },
      { field: 'ldws' },
      { field: 'dws' },
      { field: 'last_sold' },
    ],
  },

  {
    groupId: 'Volume',
    children: [
      { field: 'tickets' },
      { field: 'pct_listed' },
      { field: 'pct_sold' },
      { field: 'pct_delisted' },
    ],
  },

  {
    groupId: 'Earnings',
    children: [
      { field: 'unsold_model_diff_avg' },
      { field: 'sold_model_diff_avg' },
      { field: 'sold_value' },
      { field: 'unlisted_value' },
      { field: 'delisted_value' },
      { field: 'listed_value' },
      { field: 'list_change_avg' },
    ],
  },

  {
    groupId: 'Package Details',
    children: [
      { field: 'season' },
      { field: 'is_playoff' },
      { field: 'league' },
      { field: 'team' },
      { field: 'section' },
      { field: 'row' },
      { field: 'seats' },
      { field: 'amp' },
      { field: 'is_shown' },
    ],
  },
];

const buildFilterSpecs = (baseData: BaseDataProvider) => {
  const filter_specs: QuickFilterSpec<number | LeagueId>[] = [
    {
      id: 'season',
      label: 'Season',
      optionValues: _.map(
        (start_year) => ({
          value: start_year,
          label: start_year.toString(),
        }),
        baseData.getSeasonStartYears(),
      ),
    },
    {
      id: 'league',
      label: 'League',
      optionValues: _.map(
        (league_id) => ({ label: league_id, value: league_id }),
        baseData.getLeagueIds(),
      ),
    },
    { id: 'is_shown', label: 'Showing?', yesNoAny: true },
    { id: 'amp', label: 'AMP?', yesNoAny: true },
  ];
  return filter_specs;
};

const hydrateRows = (
  baseData: BaseDataProvider,
  rows: { client_id: number; team_id: number }[],
) => {
  return rows.map((row) => {
    const client = baseData.getClient(row.client_id);
    const hteam = baseData.getTeam(row.team_id);
    return {
      ...row,
      client_name: client?.first_name + ' ' + client?.last_name,
      team: hteam?.name_short,
      team_abbrev: hteam?.abbrev,
    };
  });
};

export default function PackagePricingDataGrid({ rows }: { rows: any[] }) {
  const { baseData } = useBaseData();
  rows = hydrateRows(baseData, rows);
  return PageDataGrid({
    id: 'ticket_package_pricing',
    rows: _.filter((r) => r.is_client || baseData.include_test_data, rows),
    column_specs,
    column_groups,
    filter_specs: buildFilterSpecs(baseData),
  });
}
