import h from '../../lib/react-hyperscript';
import * as _ from 'ramda';
import { innerHeight } from '../util/gfx';
import { type SparklineData, sparklineSvg } from '../gfx/charts';

import {
  line as d3_line,
  select as d3_select,
  scaleLinear as d3_scaleLinear,
} from 'd3';
import { useEffect, useRef } from 'react';

function Sparkline({
  width = '100%',
  height = '100%',
  padding = '0 0 0 0',
  data,
  points_shown,
  show_end_dot = false,
  show_value_on_hover = false,
  two_colors = true,
}: {
  width?: string;
  height?: string;
  padding?: string;
  data: SparklineData;
  points_shown: number;
  show_end_dot?: boolean;
  show_value_on_hover?: boolean;
  two_colors?: boolean;
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && data.points.length) {
      const { width, height } = innerHeight(ref.current);
      (ref.current as HTMLElement).replaceChildren();
      const svg = sparklineSvg({
        height,
        width,
        points_shown,
        show_end_dot,
        show_value_on_hover,
        two_colors,
      })(data);
      (ref.current as HTMLElement).appendChild(svg);
    }
  }, []);

  return h('div.sparkline', {
    ref,
    style: {
      height,
      width,
      padding,
      boxSizing: 'border-box',
    },
  });
}

export { Sparkline };
